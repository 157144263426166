import React from 'react';
import style2 from '../../../assets/styles/pagination.module.css';


const Pagination = ({ currentPage, setCurrentPage, limit, totalCount, onPrevious, onNext, offset }) => {
  const handlePageChange = (e) => {
    let newPage = Number(e.target.value);
    const maxPage = Math.ceil(totalCount / limit);

    if (isNaN(newPage) || newPage === 0) {
      newPage = ""; 
    } else if (newPage > maxPage) {
      newPage = maxPage; 
    } else if (newPage < 1) {
      newPage = 1; 
    }

    setCurrentPage(newPage); 
  };

  const handleBlur = () => {
    if (currentPage === "") {
      setCurrentPage(1); 
    }
  };

  return (
    <div className={style2.pagination}>
      <div className={style2.number}>
        The Page you are on &nbsp; &nbsp;
        <input
          type="number"
          className={style2.currentpage}
          value={currentPage}
          onChange={(e) => handlePageChange(e)}
          onBlur={handleBlur}
        />
      </div>
      <div className={style2.line}>
        <svg
          width="2"
          height="24"
          viewBox="0 0 2 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            opacity="0.1"
            x1="0.695312"
            y1="23.5"
            x2="0.695311"
            y2="0.5"
            stroke="#764FDB"
            strokeLinecap="round"
          />
        </svg>
      </div>
      <div className={style2.change}>
        <button onClick={() => onPrevious()}>
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.5622 12.7803C10.2635 13.0732 9.7793 13.0732 9.48064 12.7803L5.14685 8.53033C4.84819 8.23744 4.84819 7.76256 5.14685 7.46967L9.48064 3.21967C9.7793 2.92678 10.2635 2.92678 10.5622 3.21967C10.8608 3.51256 10.8608 3.98744 10.5622 4.28033L6.76921 8L10.5622 11.7197C10.8608 12.0126 10.8608 12.4874 10.5622 12.7803Z"
              fill="#00ABFB"
            />
          </svg>
        </button>
        <div className={style2.current}>
        {offset}- {(offset+limit > totalCount) ? totalCount : (offset + limit)} of {totalCount}
        </div>
        <button onClick={() => onNext()}>
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.63122 3.21967C6.92989 2.92678 7.41413 2.92678 7.7128 3.21967L12.0466 7.46967C12.3452 7.76256 12.3452 8.23744 12.0466 8.53033L7.7128 12.7803C7.41413 13.0732 6.92989 13.0732 6.63122 12.7803C6.33256 12.4874 6.33256 12.0126 6.63122 11.7197L10.4242 8L6.63122 4.28033C6.33256 3.98744 6.33256 3.51256 6.63122 3.21967Z"
              fill="#00ABFB"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
