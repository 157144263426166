import { React, useEffect, useState } from "react";

import style from "../../../../../assets/styles/testCreationStyle.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Get_Product_Type, Publish_Test } from "../../../../../redux/action";
import moment from "moment";



const AdvanceDetails = ({ publish, limit, offset }) => {

    const dispatch = useDispatch();

    const { testDetails } = useSelector((state) => state.GetAllData);
    const { testCreationId } = useSelector((state) => state.GetAllData);
    // call test rendering template
    useEffect(() => {
        dispatch(Get_Product_Type());
    }, []);

    const [settings, setSettings] = useState({
        rendering_template: null,
        calculator: null,
        switching_groups_allowed: false,
        test_duration_at: 0,
        overall_test_duration: {
            hours: null,
            minutes: null,
            seconds: null,
        },
    });

    // section setting
    const [levelSetting, setlevelSetting] = useState(null);

    useEffect(() => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            ...levelSetting,
        }));
    }, [levelSetting]);

 
    useEffect(() => {
        if (publish) {
            dispatch(Publish_Test(settings, testCreationId, limit, offset));
        }
    }, [publish]);


    return (
        <div className={style.masterTestCreation}>
            <form className={` ${style.testCreationArea} ${style.AdvanceBox}`}>
                <div className={style.AdvanceSettingsContainer}>
                    <h5 style={{ fontSize: "18px" }} className={style.testMarklabel}>
                        Advance Settings
                    </h5>

                    <div className={style.bound}>

                        {/* Test type */}
                        <div className={style.templateDrop}>
                            <div className={style.templateDrop_item}>
                                <div className={style.templateGroup}>
                                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                                        Calculator To Use
                                    </span>
                                    <select
                                        disabled
                                        value={
                                            testDetails.calculator === 0 ? "None" : testDetails.calculator === 1 ? "Basic" : "Scientific"
                                        }
                                        name="question"
                                        id="question"
                                        className={`${style.template_drop_filter} ${style.fullbg_section}`}
                                    >
                                        <option>None</option>
                                        <option>Basic</option>
                                        <option>Scientific</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className={style.templateDrop}>
                            <div className={style.templateDrop_item}>
                                <div className={style.templateGroup}>
                                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                                        Result Date/ Time
                                    </span>
                                    <input
                                    type="text"
                                    value={moment(testDetails.result_date_time).format("DD/MM/YYYY, h:mm a")}
                                    disabled
                                        name="question"
                                        id="question"
                                        className={ `${style.template_drop_filter} ${style.fullbg_section}` }
                                    />
                                      
                                
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* <div className={style.switching}>
                        <span style={{ fontSize: "14px", fontWeight: "500" }}>
                            Switching Groups Allowed
                        </span>
                        <div className={style.wrapperIcon}>
                            {testDetails.switching_groups_allowed === false ? (
                                <div className={`${style.iconBoxs} ${style.editOption}`}>
                                    No
                                </div>
                            ) : (
                                <div className={`${style.iconBoxs}`}>Yes</div>
                            )}{" "}
                        </div>
                    </div> */}

                    <p
                        style={{ fontWeight: "500", marginTop: "30px" }}
                        className={style.marketLogic}
                    >
                        Test Duration At
                    </p>

                    <div style={{ fontSize: "13px" }} className={style.levels}>
                        {testDetails.test_duration_at === 0 ? (
                            <>
                                <input
                                    disabled
                                    type="radio"
                                    name="markingLogic"
                                    defaultChecked
                                    value={0}
 
                                />
                                <label htmlFor="test level">Test Level</label>
                            </>
                        ) : testDetails.test_duration_at === 1 ? (
                            <>
                                <input
                                    disabled
                                    style={{ marginLeft: "20px" }}
                                    name="markingLogic"
                                    type="radio"
                                    value={1}
 
                                />
                                <label htmlFor="section level">Group Level</label>
                            </>
                        ) : (
                            <>
                                <input
                                    style={{ marginLeft: "20px" }}
                                    name="markingLogic"
                                    type="radio"
                                    value={2}
                                    disabled
 
                                />
                                <label htmlFor="section level">Section Level Level</label>
                            </>
                        )}
                    </div>

                    <div className={style.screenNum}>
                        <p style={{ fontWeight: 500 }}>Overall Test Duration</p>

                        <div className={style.timer}>
                            <input
                                className={` ${style.timer_input} ${style.fullbg_section}`}
                                type="number"
                                name="number"
                                min="-100"
                                max="100"
                                step="1"
                                value={testDetails.duration.hours}
                                disabled
 
                            />
                            <label htmlFor="minutes">Hours</label>

                            <input
                                className={` ${style.timer_input} ${style.fullbg_section}`}
                                type="number"
                                name="number"
                                min="-100"
                                max="100"
                                step="1"
                                value={testDetails.duration.minutes}
                                disabled
 
                            />
                            <label htmlFor="minutes">Minutes</label>

                            <input
                                className={` ${style.timer_input} ${style.fullbg_section}`}
                                type="number"
                                name="number"
                                min="-100"
                                max="100"
                                step="1"
                                value={testDetails.duration.seconds}
                                disabled
   
                            />
                            <label htmlFor="second">Seconds</label>
                        </div>
                    </div>
                </div>
              
            </form>
        </div>
    );
};

export default AdvanceDetails;
