import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CallWithAuth } from "../../../action/apiActions";
import {
  GET_AUTHOR,
  GET_CATEGORY,
  GET_FREE_TAG,
  GET_SUBJECT_TAG,
} from "../../../action/apiPath";

import style from "../../../assets/styles/modalStyle.module.css";
import { Get_Product_Type } from "../../../redux/action";

const TestFilterModal = ({
  onFilterStateChange,
  closeModalFilter,
  setFiltersApplied,
}) => {
  // _________ AUTHIR TAG STATE INITIALIZATION _________
  const [multiAuthorTaginput, setmultiAuthorTaginput] = useState("");
  const [multiAuthorTag, setmultiAuthorTag] = useState([]);
  const [multiAuthorTagStatus, setmultiAuthorTagStatus] = useState(false);
  const [multiselectedAuthorTag, setmultiSelectedAuthorTag] = useState([]);

  // console.log("Multis authir are:", multiselectedAuthorTag);

  // CALL GET API FOR getting TAGS
  const TagAuthorMultiple = async (e) => {
    setmultiAuthorTaginput(e);
    if (e.length > 2) {
      const data = await CallWithAuth("GET", GET_AUTHOR + "?keyword=" + e);
      // console.log(data);
      if (data.status && data.res.status === 200) {
        setmultiAuthorTag(data.res.data);
        if (data.res.data.length > 0) {
          setmultiAuthorTagStatus(true);
        } else {
          setmultiAuthorTagStatus(false);
        }
      }
    } else {
      setmultiAuthorTagStatus(false);
    }
  };

  // select tags from options
  const selectAuthorTag = (e) => {
    setmultiSelectedAuthorTag((current) => [...current, e]);
    setmultiAuthorTagStatus(false);
    setmultiAuthorTaginput("");
  };

  // _________ PRIMARY TAG STATE INITIALIZATION _________

  // _________ Free TAG STATE INITIALIZATION _________
  const [multiFreeTaginput, setmultiFreeTaginput] = useState("");
  const [multiFreeTag, setmultiFreeTag] = useState([]);
  const [multiFreeTagStatus, setmultiFreeTagStatus] = useState(false);
  const [multiselectedFreeTag, setmultiSelectedFreeTag] = useState([]);

  // CALL GET API FOR getting TAGS
  const TagFreeMultiple = async (e) => {
    setmultiFreeTaginput(e);
    if (e.length > 2) {
      const data = await CallWithAuth("GET", GET_FREE_TAG + "?keyword=" + e);
      // console.log(data);
      if (data.status && data.res.status === 200) {
        setmultiFreeTag(data.res.data);
        if (data.res.data.length > 0) {
          setmultiFreeTagStatus(true);
        } else {
          setmultiFreeTagStatus(false);
        }
      }
    } else {
      setmultiFreeTagStatus(false);
    }
  };

  // select tags from options
  const selectFreeTag = (e) => {
    setmultiSelectedFreeTag((current) => [...current, e]);
    setmultiFreeTagStatus(false);
    setmultiFreeTaginput("");
  };

  // const modalRef = useRef();

  // const handleOverlayClick = (event) => {
  //     if (modalRef.current && !modalRef.current.contains(event.target)) {
  //         closeModalFilter();
  //     }
  // };

  // useEffect(() => {
  //     // Attach the event listener when the component mounts
  //     document.addEventListener('mousedown', handleOverlayClick);

  //     // Clean up the event listener when the component unmounts
  //     return () => {
  //         document.removeEventListener('mousedown', handleOverlayClick);
  //     };
  // }, [handleOverlayClick]);

  // __________________ outside clickable close modal _____________________

  const rowRef = useRef();

  // const handleRowClick = (event) => {
  //     if (rowRef.current && !rowRef.current.contains(event.target)) {
  //         // Check if the click is within the action buttons
  //         const isActionButtonClick = event.target.closest('.action_buttons');

  //         if (!isActionButtonClick) {
  //             setmultiSubjectTagStatus(false)
  //             setmultiCategoryTagStatus(false)
  //             setmultiFreeTagStatus(false)
  //             setmultiAuthorTagStatus(false)
  //         }
  //     }
  // };

  // useEffect(() => {
  //     document.addEventListener('mousedown', handleRowClick);

  //     return () => {
  //         document.removeEventListener('mousedown', handleRowClick);
  //     };
  // }, [handleRowClick]);
  const { getTestCreationData } = useSelector((state) => state.GetAllData);

  /** ____________ Filter for id ________ */
  const [searchId, setSearchId] = useState("");
  const [matchingIds, setMatchingIds] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleSearchInput = (event) => {
    const input = event.target.value;
    setSearchId(input);

    if (Array.isArray(getTestCreationData?.results)) {
      const filteredIds = getTestCreationData?.results?.filter((item) => {
        return (
          item.id &&
          typeof item.id === "number" &&
          item.id === parseInt(input, 10)
        );
      });

      setMatchingIds(filteredIds);
      setIsDropdownVisible(true);
    } else {
      // console.log("getTestCreationData is not an array or is undefined");
      setMatchingIds([]);
    }
  };

  const handleSelectId = (id) => {
    setSelectedId((prevIds) => [...prevIds, id]);

    setSearchId("");
    setMatchingIds([]);
    setIsDropdownVisible(false);
  };
  // console.log("Selected ids are", selectedId);
  // ____________________________________________________________________________//

  // ____________________________ Filter for status ________________//

  const [filteredStatus, setFilteredStatus] = useState([]);

  const [selecteStatus, setSelecteStatus] = useState([]);

  const [inputValue, setInputValue] = useState("");

  const [filterDropdown, setFilterDropdown] = useState(false);

  const filterStatus = (input) => {
    const lowerCaseInput = input.toLowerCase();
    const filtered = getTestCreationData?.results?.filter((name) =>
      name?.status?.toLowerCase().startsWith(lowerCaseInput)
    );

    const nameOptions = filtered?.map((obj) => obj?.status);

    const uniqueNames = [...new Set(nameOptions)];
    setFilteredStatus(uniqueNames);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    if (value.length > 1) {
      filterStatus(value);
      setFilterDropdown(true);
    } else {
      setFilteredStatus([]);
      setFilterDropdown(false);
    }
  };

  const handleSelectionChange = (name) => {
    setSelecteStatus((prevNames) => [...prevNames, name]);
    setInputValue("");
    setFilterDropdown(false);
  };
  // console.log("Status are", selecteStatus);
  // ____________________________________________________________________________//

  // ____________________________ Filter for academic year ________________//

  const years = [];

  for (let year = 2020; year <= 2025; year++) {
    years.push(year);
  }

  const [academicYear, setAcademicYear] = useState(null);
  // console.log("Year passing", academicYear);

  const handleAcademicYear = (e) => {
    const selectedYear = Number(e.target.value);
    setAcademicYear(selectedYear);
    // console.log("My acadmecic year", selectedYear);
  };
  // ____________________________________________________________________________//

  // ____________________________ Filter for template ________________//
  const dispatch = useDispatch();
  const { getProductType } = useSelector((state) => state.GetAllData);
  // console.log("My template data is ->>>>>>", getProductType);
  useEffect(() => {
    dispatch(Get_Product_Type());
  }, [dispatch]);

  const [player, setPlayer] = useState([]);

  // console.log("My player data ->>", player);

  const [selecetdPlayer, setSelectedPlayer] = useState([]);

  const [playerInput, setPlayerInput] = useState("");

  const [playerDropdown, setPlayerDropdwon] = useState(false);

  const playerStatus = (input) => {
    const lowerCaseInput = input.toLowerCase();
    const authored = getProductType?.filter((name) =>
      name?.product_type?.toLowerCase().startsWith(lowerCaseInput)
    );

    const nameOptions = authored?.map((player) => ({
      id: player.id,
      product_type: player.product_type,
    }));

    const uniquePlayers = [...new Set(nameOptions.map((player) => player.id))];
    setPlayer(
      uniquePlayers.map((id) => nameOptions.find((player) => player.id === id))
    );
  };

  const handlePlayerInputChange = (event) => {
    const value = event.target.value;
    setPlayerInput(value);

    if (value.length > 1) {
      playerStatus(value);
      setPlayerDropdwon(true);
    } else {
      setPlayer([]);
      setPlayerDropdwon(false);
    }
  };

  const handlePlayerChange = (player) => {
    setSelectedPlayer((prevIds) => [...prevIds, player]);
    setPlayerInput("");
    setPlayerDropdwon(false);
  };
  // console.log("Final players are", selecetdPlayer);

  // ____________________________________________________________________________//

  const handle = () => {
    onFilterStateChange(
      multiselectedAuthorTag,
      multiselectedFreeTag,
      selectedId,
      selecteStatus,
      academicYear,
      selecetdPlayer
    );
    setFiltersApplied(true);
    closeModalFilter();
  };

  return (
    <div
      style={{ background: "rgba(0,0,0,0.1)" }}
      className={style.filterModal}
    >
      <div className={style.overlay}></div>
      <div className={style.center_div}>
        {/* __________ modal top header __________ */}
        <div className={style.header_top}>
          {/* __________ title __________ */}
          <div className={style.title}>
            <div>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.39844 2.60156H18.5984C19.6984 2.60156 20.5984 3.50156 20.5984 4.60156V6.80156C20.5984 7.60156 20.0984 8.60156 19.5984 9.10156L15.2984 12.9016C14.6984 13.4016 14.2984 14.4016 14.2984 15.2016V19.5016C14.2984 20.1016 13.8984 20.9016 13.3984 21.2016L11.9984 22.1016C10.6984 22.9016 8.89844 22.0016 8.89844 20.4016V15.1016C8.89844 14.4016 8.49844 13.5016 8.09844 13.0016L4.29844 9.00156C3.79844 8.50156 3.39844 7.60156 3.39844 7.00156V4.70156C3.39844 3.50156 4.29844 2.60156 5.39844 2.60156Z"
                  stroke="#212529"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span> &nbsp; Filter</span>
            </div>
          </div>
          {/* __________ close __________  */}
          <div className={style.close}>
            <button onClick={() => closeModalFilter()}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.34375 6.34375L17.6575 17.6575"
                  stroke="#212529"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.34254 17.6575L17.6562 6.34375"
                  stroke="#212529"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        {/* __________ modal bottom FIELD __________ */}

        {/* question author  */}

        <div className={style.bottom_fields}>
          <div className={style.bind}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              className={style.subject}
            >
              <p
                style={{ fontWeight: "700", textAlign: "left", width: "100%" }}
              >
                Test ID{" "}
              </p>
              <div className={style.search_listing}>
                <div className={style.tag_icons}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="20" height="20" rx="4" fill="#272727" />
                    <path
                      d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                      fill="white"
                    />
                  </svg>
                </div>

                <div className={style.input_search}>
                  <div className={style.appear}>
                    <div className={style.conStat}>
                      {Array.isArray(selectedId) &&
                        selectedId.map((id, index) => (
                          <div key={index} className={style.buttonStyle}>
                            {id}
                          </div>
                        ))}
                    </div>
                    <input
                      type="search"
                      id="searchInput"
                      placeholder=""
                      value={searchId}
                      onChange={handleSearchInput}
                    />
                  </div>
                  {isDropdownVisible && matchingIds.length > 0 && (
                    <div className={style.list_input_primary}>
                      <ul>
                        {matchingIds.map((item) => (
                          <li
                            onClick={() => handleSelectId(item.id)}
                            key={item.id}
                          >
                            {item.id}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <div className={style.cancel_button}>
                  <button onClick={() => setSelectedId("")}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.75781 7.75781L16.2431 16.2431"
                        stroke="#272727"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.75691 16.2431L16.2422 7.75781"
                        stroke="#272727"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className={style.templateDrop}>
              <div className={style.templateDrop_item}>
                <div className={style.templateGroup}>
                  <span>Academic Year </span>

                  <select
                    name="question"
                    id="question"
                    className={style.template_drop_filter}
                    onClick={handleAcademicYear}
                  >
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ display: "flex", alignItems: "center", gap: "20px" }}
            className={style.tags}
          >
            <div className={style.subject}>
              <p style={{ fontWeight: "700", textAlign: "left" }}>
                Product Template{" "}
              </p>
              <div
                style={{ marginTop: "20px" }}
                className={style.search_listing}
              >
                <div className={style.tag_icons}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="20" height="20" rx="4" fill="#272727" />
                    <path
                      d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                      fill="white"
                    />
                  </svg>
                </div>

                <div style={{ display: "flex" }} className={style.input_search}>
                  {/* {multiselectedAuthorTag?.map((items, i) => (
                    <>
                      <span className={style.selected_tags}>
                        {items.first_name}-{items.last_name}{" "}
                      </span>
                    </>
                  ))} */}
                  <div className={style.appear}>
                    <div className={style.conStat}>
                      {selecetdPlayer?.map((player, index) => (
                        <div key={index} className={style.buttonStyle}>
                          {player.product_type}
                        </div>
                      ))}{" "}
                    </div>
                    <input
                      className={style.bar}
                      type="search"
                      id="searchInput"
                      placeholder=""
                      value={playerInput}
                      onChange={handlePlayerInputChange}
                    />
                  </div>

                  {playerDropdown && (
                    <div className={style.list_input_primary}>
                      <ul>
                        {" "}
                        {player?.map((player) => (
                          <li
                            onClick={() => handlePlayerChange(player)}
                            key={player.id}
                            value={player}
                          >
                            {player.product_type}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <div className={style.cancel_button}>
                  <button onClick={() => setSelectedPlayer([])}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.75781 7.75781L16.2431 16.2431"
                        stroke="#272727"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.75691 16.2431L16.2422 7.75781"
                        stroke="#272727"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column" }}
              className={style.subject}
            >
              <p style={{ fontWeight: "700", textAlign: "left" }}>Status </p>
              <div className={style.search_listing}>
                <div className={style.tag_icons}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="20" height="20" rx="4" fill="#272727" />
                    <path
                      d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                      fill="white"
                    />
                  </svg>
                </div>

                <div className={style.input_search}>
                  {/* {multiselectedFreeTag?.map((items, i) => (
                                        <>
                                            <span className={style.selected_tags}>{items.name} </span>
                                        </>
                                    ))} */}

                  <div className={style.appear}>
                    <div className={style.conStat}>
                      {selecteStatus?.map((status, index) => (
                        <div
                          key={index}
                          className={style.buttonStyle}
                        >
                          {status}
                        </div>
                      ))}
                    </div>
                    <input
                      type="search"
                      id="searchInput"
                      placeholder=""
                      value={inputValue}
                      onChange={handleInputChange}
                    />
                  </div>
                  {filterDropdown && (
                    <div className={style.list_input_primary}>
                      <ul>
                        {" "}
                        {filteredStatus?.map((name, index) => (
                          <li
                            onClick={() => handleSelectionChange(name)}
                            key={index}
                            value={name}
                          >
                            {name}
                          </li>
                        ))}{" "}
                      </ul>
                    </div>
                  )}
                </div>

                <div className={style.cancel_button}>
                  <button onClick={() => setSelecteStatus([])}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.75781 7.75781L16.2431 16.2431"
                        stroke="#272727"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.75691 16.2431L16.2422 7.75781"
                        stroke="#272727"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ display: "flex", alignItems: "center", gap: "20px" }}
            className={style.tags}
          >
            <div className={style.subject}>
              <p style={{ fontWeight: "700", textAlign: "left" }}>
                Question Author(s){" "}
              </p>
              <div
                style={{ marginTop: "20px" }}
                className={style.search_listing}
              >
                <div className={style.tag_icons}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="20" height="20" rx="4" fill="#272727" />
                    <path
                      d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                      fill="white"
                    />
                  </svg>
                </div>

                <div className={style.input_search}>
                  {multiselectedAuthorTag?.map((items, i) => (
                    <>
                      <span className={style.selected_tags}>
                        {items.first_name}-{items.last_name}{" "}
                      </span>
                    </>
                  ))}

                  <input
                    type="search"
                    id="searchInput"
                    placeholder=""
                    value={multiAuthorTaginput}
                    onChange={(e) => TagAuthorMultiple(e.target.value)}
                  />

                  {multiAuthorTagStatus ? (
                    <div className={style.list_input_primary}>
                      {multiAuthorTag
                        ?.filter(
                          (item) =>
                            !multiselectedAuthorTag.some(
                              (filterItem) => item.id === filterItem.id
                            )
                        )
                        .map((items, i) => (
                          <>
                            <div onClick={() => selectAuthorTag(items)}>
                              <span>
                                {items.first_name}-{items.last_name}{" "}
                              </span>
                            </div>
                          </>
                        ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className={style.cancel_button}>
                  <button onClick={() => setmultiSelectedAuthorTag([])}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.75781 7.75781L16.2431 16.2431"
                        stroke="#272727"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.75691 16.2431L16.2422 7.75781"
                        stroke="#272727"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column" }}
              className={style.subject}
            >
              <p style={{ fontWeight: "700", textAlign: "left" }}>Free Tags </p>
              <div className={style.search_listing}>
                <div className={style.tag_icons}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="20" height="20" rx="4" fill="#272727" />
                    <path
                      d="M5.64103 10.7693H9.23072V14.359C9.23072 14.7179 9.51278 15 9.87175 15C10.2307 15 10.5128 14.7179 10.5128 14.359V10.7693H14.359C14.7179 10.7693 15 10.4872 15 10.1283C15 9.76928 14.7179 9.48722 14.359 9.48722H10.5128V5.64103C10.5128 5.28206 10.2307 5 9.87175 5C9.51278 5 9.23072 5.28206 9.23072 5.64103V9.48722H5.64103C5.28206 9.48722 5 9.76928 5 10.1283C5 10.4872 5.28206 10.7693 5.64103 10.7693Z"
                      fill="white"
                    />
                  </svg>
                </div>

                <div className={style.input_search}>
                  {multiselectedFreeTag?.map((items, i) => (
                    <>
                      <span className={style.selected_tags}>{items.name} </span>
                    </>
                  ))}

                  <input
                    type="search"
                    id="searchInput"
                    placeholder=""
                    value={multiFreeTaginput}
                    onChange={(e) => TagFreeMultiple(e.target.value)}
                  />

                  {multiFreeTagStatus ? (
                    <div className={style.list_input_primary}>
                      {multiFreeTag
                        ?.filter(
                          (item) =>
                            !multiselectedFreeTag?.some(
                              (filterItem) => item.id === filterItem.id
                            )
                        )
                        .map((items, i) => (
                          <>
                            <div onClick={() => selectFreeTag(items)}>
                              <span>{items.name} </span>
                            </div>
                          </>
                        ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className={style.cancel_button}>
                  <button onClick={() => setmultiSelectedFreeTag([])}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.75781 7.75781L16.2431 16.2431"
                        stroke="#272727"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.75691 16.2431L16.2422 7.75781"
                        stroke="#272727"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* __________ apply and save button __________ */}
          <div>
            <button onClick={() => handle()} className={style.blue_button}>
              Apply Selected Filter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestFilterModal;
